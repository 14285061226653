import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  className?: string
  description?: string
  image?: ImageProps
  title: string
}

export const Item = memo(function Item({
  className,
  description,
  image,
}: Props) {
  return (
    <Container className={className}>
      {image ? <Image {...image} /> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  &.active {
    opacity: 1;

    img {
      transform: scale(1);
    }
    div {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.1);
    transition: 0.3s ease-in-out;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  padding: 3.75rem 13.194vw;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-3.75rem, -50%);
  transition: 0.6s ease-in-out;
  z-index: 2;

  @media (max-width: 1023px) {
    padding: 1.9375rem;
  }
`
